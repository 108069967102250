import React from 'react'
import { Link, useParams, useRouteMatch } from 'react-router-dom'
import { Divider, Menu } from 'semantic-ui-react'

import { useBreakpoints } from '@procsea/design-system'

import CompanyDisplay from 'src/components/presentationals/navigation/account/CompanyDisplay'
import LanguageMenuItem from 'src/components/presentationals/navigation/account/LanguageMenuItem'
import LogoutMenuItem from 'src/components/presentationals/navigation/account/LogoutMenuItem'
import StopImpersonationMenuItem from 'src/components/presentationals/navigation/account/StopImpersonationMenuItem'
import TermsOfUseMenuItem from 'src/components/presentationals/navigation/account/TermsOfUseMenuItem'
import { useUser } from 'src/contexts/userContext'
import { LocaleParams, UserType } from 'src/types/router'

import { useCurrentBuyerCompany, useCurrentMarketplaceId } from '../../../shared/hooks'
import './BuyerAccountInformation.css'
import BuyerAccountInformationHeader from './BuyerAccountInformationHeader'

export interface BuyerAccountInformationProps {
  analyticsFirstTab?: string
  onClose?: () => void
}

const BuyerAccountInformation = ({ analyticsFirstTab, onClose }: BuyerAccountInformationProps) => {
  const { locale } = useParams<LocaleParams>()
  const { isMobile, isTablet } = useBreakpoints()
  const { url } = useRouteMatch()
  const buyerCompany = useCurrentBuyerCompany()
  const marketplaceId = useCurrentMarketplaceId()
  const user = useUser()

  return (
    <div className="account-information-content" data-e2e="account-information-content">
      <Menu secondary size="large" vertical className="account-information-links">
        <BuyerAccountInformationHeader companyName={buyerCompany?.name} />

        {(isMobile || isTablet) && (
          <CompanyDisplay
            companyName={buyerCompany?.name}
            companyId={buyerCompany?.id}
            impersonableUsers={user?.impersonableUsers}
          />
        )}

        <Divider />

        {isMobile && marketplaceId && (
          <Menu.Item
            as={Link}
            data-e2e="invoices-menu-item"
            to={`${[url, 'marketplace', marketplaceId.toString(), 'invoicing'].join('/')}/`}
            onClick={onClose}
          >
            {gettext('Invoices')}
          </Menu.Item>
        )}

        {isMobile && marketplaceId && analyticsFirstTab && (
          <Menu.Item
            as={Link}
            to={`${[
              url,
              'marketplace',
              marketplaceId.toString(),
              'analytics',
              analyticsFirstTab,
            ].join('/')}/`}
            onClick={onClose}
          >
            {gettext('Analytics')}
          </Menu.Item>
        )}

        <Menu.Item
          as={Link}
          data-e2e="my-suppliers-menu-item"
          onClick={onClose}
          to={`/${locale}/${UserType.BUYER}/marketplace/${marketplaceId}/suppliers/`}
        >
          {gettext('My suppliers')}
        </Menu.Item>

        <TermsOfUseMenuItem locale={locale} onClick={onClose} />

        <LogoutMenuItem />

        {user?.isImpersonate && <StopImpersonationMenuItem />}

        <Divider />

        <LanguageMenuItem />
      </Menu>
    </div>
  )
}

export default BuyerAccountInformation
