import classNames from 'classnames'
import { compact } from 'lodash'
import React from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { useBreakpoints } from '@procsea/design-system'

import SideBar, { UserInfo } from 'src/components/base/design-system/SideBar/SideBar'
import { NavItem } from 'src/components/base/design-system/SideBar/SideNavItem/SideNavItem'
import TabBar from 'src/components/base/design-system/TabBar/TabBar'
import BuyerAccountMenuItem from 'src/components/presentationals/buyer-dashboard/navigation/menu-items/account/BuyerAccountMenuItem'
import 'src/components/presentationals/buyer-dashboard/shared/hooks'
import {
  useCurrentBuyerCompany,
  useDefaultMarketplaceId,
} from 'src/components/presentationals/buyer-dashboard/shared/hooks'
import HelpMenu from 'src/components/presentationals/navigation/HelpMenu'
import { AppEnvironment } from 'src/components/presentationals/shared/navigation/'
import { useCurrentMarketplace, useUser } from 'src/contexts/userContext'
import { useOptionFlags } from 'src/hooks'
import { usePaginatedDashboardsQuery } from 'src/queries/buyer'
import { BuyerCompanyAsBuyer } from 'src/types'

import { useUnreadChatMessagesCount } from '../../chat/store/useChatStore'
import MainMenu from '../../navigation/MainMenu/MainMenu'
import './BuyerNavigation.css'
import BuyerMobileHeader from './mobile-header/BuyerMobileHeader'

const StyledSideBar = styled(SideBar)`
  height: 100vh;
  position: sticky;
  flex: 0 0 auto;
  left: 0;
  top: 0;
  z-index: 14;
  overflow-y: auto;
  overflow-x: hidden;
`

export interface GetBuyerNavItemsArgs {
  analyticsFirstTab: string | undefined
  buyerCompany?: BuyerCompanyAsBuyer
  canUseMapping?: boolean
  isMobile?: boolean
  marketplaceId?: string
  nbPurchaseUnmapped: number
  optionFlagCanUseOrders?: boolean
  pathname: string
  unreadChatMessagesCount: number
  url: string
}

export const getBuyerNavItems = ({
  analyticsFirstTab,
  buyerCompany,
  canUseMapping,
  isMobile,
  marketplaceId,
  nbPurchaseUnmapped,
  optionFlagCanUseOrders,
  pathname,
  unreadChatMessagesCount,
  url,
}: GetBuyerNavItemsArgs) =>
  compact<NavItem>([
    {
      'data-e2e': 'market-nav-item',
      icon: 'basket-shopping',
      isActive: pathname.includes('/market/'),
      title: gettext('Market'),
      to: `${[url, 'marketplace', marketplaceId, 'market'].join('/')}/`,
    },
    optionFlagCanUseOrders && {
      'data-e2e': 'orders-nav-item',
      icon: 'truck-fast',
      title: gettext('Orders'),
      to: `${[url, 'marketplace', marketplaceId, 'orders'].join('/')}/`,
    },
    !isMobile && {
      'data-e2e': 'invoicing-nav-item',
      icon: 'receipt',
      isActive: pathname.includes('/invoicing/'),
      title: gettext('Invoices'),
      to: `${[url, 'marketplace', marketplaceId, 'invoicing'].join('/')}/`,
    },
    buyerCompany?.canUseChat && {
      'data-e2e': 'chat-nav-item',
      icon: 'comments',
      notificationCount: unreadChatMessagesCount,
      title: gettext('Chat'),
      to: `${[url, 'marketplace', marketplaceId, 'chat'].join('/')}/`,
    },
    !isMobile &&
      analyticsFirstTab && {
        'data-e2e': 'analytics-nav-item',
        icon: 'chart-column',
        isActive: pathname.includes('/analytics/'),
        title: gettext('Analytics'),
        to: `${[url, 'marketplace', marketplaceId, 'analytics', analyticsFirstTab].join('/')}/`,
      },
    canUseMapping && {
      'data-e2e': 'mapping-item',
      icon: 'sliders',
      isActive: pathname.includes(`/mapping/`),
      notificationCount: nbPurchaseUnmapped,
      title: gettext('Mapping'),
      to: `${[url, 'marketplace', marketplaceId, 'mapping/purchases'].join('/')}/`,
    },
  ])

const BuyerNavigation = () => {
  const { pathname } = useLocation()
  const user = useUser()
  const { isMobile, isTablet, isDesktop } = useBreakpoints()
  const { url } = useRouteMatch()
  const { optionFlagCanUseMapping } = useOptionFlags()

  const defaultMarketplaceId = useDefaultMarketplaceId()

  const buyerCompany = useCurrentBuyerCompany()
  const currentMarketplace = useCurrentMarketplace(defaultMarketplaceId)

  const unreadChatMessagesCount = useUnreadChatMessagesCount()

  const { data: dashboardPages } = usePaginatedDashboardsQuery({ queryParams: { limit: 1 } })
  const analyticsFirstTab = dashboardPages?.pages?.[0]?.results?.[0]?.tabSlug

  const navItems = getBuyerNavItems({
    analyticsFirstTab,
    buyerCompany,
    canUseMapping: optionFlagCanUseMapping,
    isMobile,
    marketplaceId: defaultMarketplaceId?.toString(),
    nbPurchaseUnmapped: user?.nbPurchaseUnmapped ?? 0,
    optionFlagCanUseOrders: buyerCompany?.optionFlagDisplayOrdersTab,
    pathname,
    unreadChatMessagesCount,
    url,
  })

  return user?.releaseToggleNewMenu ? (
    <MainMenu />
  ) : isMobile ? (
    <>
      <BuyerMobileHeader
        logoSrc={(currentMarketplace && currentMarketplace.mobileLogo) || undefined}
      />
      <TabBar
        items={navItems}
        accountTabBarItem={<BuyerAccountMenuItem analyticsFirstTab={analyticsFirstTab} />}
      />
    </>
  ) : (
    <StyledSideBar
      data-e2e="side-bar"
      header={
        <img
          alt="marketplace-logo"
          className={classNames('marketplace-logo', { 'is-tablet': isTablet })}
          src={(currentMarketplace && currentMarketplace.desktopLogoLegacy) || undefined}
        />
      }
      navItems={navItems}
      commandMenu={
        <>
          <BuyerAccountMenuItem compact={isTablet} key="account-menu-item" />
          {isDesktop && <UserInfo fullName={user?.fullName} />}
          <AppEnvironment isTablet={isTablet} />
        </>
      }
      footer={<HelpMenu />}
      compact={isTablet}
    />
  )
}

export default BuyerNavigation
