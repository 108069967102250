import classNames from 'classnames'
import React, { useState } from 'react'
import { Popup } from 'semantic-ui-react'

import { Icon, Stack, useBreakpoints } from '@procsea/design-system'

import SideMenuItem from 'src/components/base/design-system/SideBar/SideMenuItem/SideMenuItem'

import BuyerAccountInformation from './BuyerAccountInformation'
import './BuyerAccountMenuItem.css'

export interface BuyerAccountMenuItemProps {
  analyticsFirstTab?: string
  compact?: boolean
}

const BuyerAccountMenuItem = ({ analyticsFirstTab, compact }: BuyerAccountMenuItemProps) => {
  const { isMobile } = useBreakpoints()

  const [open, setOpen] = useState(false)

  const closeModal = () => setOpen(false)

  const toggleModal = () => setOpen(!open)

  return (
    <Popup
      className={classNames('account-menu-item-contextual-menu', { compact, isMobile })}
      trigger={
        isMobile ? (
          <div className="tab-bar-item" data-e2e="account-menu-item" onClick={toggleModal}>
            <Stack spacing="xsmall">
              <Icon name="circle-user" size="2x" faStyle="far" variant="neutral" />
              <span className="tab-bar-item-title">{gettext('Account')}</span>
            </Stack>
          </div>
        ) : (
          <SideMenuItem
            data-e2e="account-menu-item"
            icon="circle-user"
            title={gettext('Account')}
            compact={compact}
            onClick={toggleModal}
          />
        )
      }
      content={
        <BuyerAccountInformation analyticsFirstTab={analyticsFirstTab} onClose={closeModal} />
      }
      on="click"
      onClose={closeModal}
      open={open}
      position={isMobile ? 'bottom right' : 'left center'}
    />
  )
}

export default BuyerAccountMenuItem
