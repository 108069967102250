import React, { Suspense, lazy, useEffect } from 'react'
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'

import { AppWrapper, PageContent } from '@procsea/design-system'

import PageLoader from 'src/components/base/loader/PageLoader'
import NotFound from 'src/components/base/notFound/NotFound'
import ErrorBoundary from 'src/components/presentationals/shared/errors/ErrorBoundary'
import { useUser } from 'src/contexts/userContext'
import useChat, { createConversationClient } from 'src/hooks/useChat'
import useOrganisationPusherSubscriptions from 'src/hooks/useOrganisationPusherSubscription'
import { useChatTokenQuery } from 'src/queries/shared'

import { useChatActions, useChatConversationClient } from '../chat/store/useChatStore'
import BuyerNavigation from './navigation/BuyerNavigation'
import { useCurrentBuyerCompany, usePusherSubscriptions } from './shared/hooks'
import useInitializeBuyerDashboard from './shared/hooks/useInitializeBuyerDashboard'

const AsyncMarketplace = lazy(
  () => import('src/components/presentationals/buyer-dashboard/marketplace/Marketplace')
)

const BuyerDashboard = () => {
  const user = useUser()
  const { search } = useLocation()
  const { path } = useRouteMatch()
  const conversationClient = useChatConversationClient()
  const { setConversationClient } = useChatActions()
  const buyerCompany = useCurrentBuyerCompany()
  const { data: chatToken } = useChatTokenQuery({ enabled: !!buyerCompany?.canUseChat })

  useChat()
  usePusherSubscriptions()
  useOrganisationPusherSubscriptions()
  const { isReady, marketplaceUrl } = useInitializeBuyerDashboard()

  useEffect(() => {
    if (buyerCompany?.canUseChat && !conversationClient && chatToken?.token) {
      const client = createConversationClient(chatToken.token)
      setConversationClient(client)
    }
  }, [buyerCompany?.canUseChat, chatToken?.token])

  if (isReady) {
    return (
      <AppWrapper>
        <BuyerNavigation />

        <PageContent isLegacy={!user?.releaseToggleNewMenu}>
          <Suspense fallback={<PageLoader />}>
            <Switch>
              <Route
                path={`${path}/marketplace/:marketplaceId(${buyerCompany?.marketplaceIds.join(
                  '|'
                )})`}
              >
                <AsyncMarketplace />
              </Route>

              <Route exact path="/:locale/:userType(buyer)">
                <Redirect to={{ pathname: marketplaceUrl, search }} />
              </Route>

              <Route>
                <ErrorBoundary>
                  <NotFound />
                </ErrorBoundary>
              </Route>
            </Switch>
          </Suspense>
        </PageContent>
      </AppWrapper>
    )
  }

  return null
}

export default BuyerDashboard
